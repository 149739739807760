/* eslint-disable react/no-array-index-key */
import { Delete } from "@mui/icons-material";
import { IconButton, List, ListItem, ListItemText } from "@mui/material";
import { useEffect, useState } from "react";

function FileList(props: any) {
  const { attachments, setAttachments } = props;

  const [files, setFiles] = useState<any>([]);

  const handleClose = (ind: any) => {
    console.log("ind", ind);

    // Remove the second file
    const newFileList = attachments.filter((_: any, index: any) => index !== 1);

    // Now you can use newFileList
    console.log(newFileList);
  };
  useEffect(() => {
    const myArray = Object.keys(attachments).map((key) => attachments[key]);
    setFiles(myArray);
  }, [attachments]);

  return (
    <List>
      {attachments.length !== 0 && (
        <IconButton
          onClick={() => {
            setAttachments([]);
          }}
        >
          <Delete />
        </IconButton>
      )}
      {files.map((file: any, index: any) => (
        <ListItem key={index}>
          <ListItemText primary={file.name} secondary={file.size} />
          {/* <IconButton edge="end" onClick={() => handleClose(index)}>
            <CloseIcon />
          </IconButton> */}
        </ListItem>
      ))}
    </List>
  );
}

export default FileList;
