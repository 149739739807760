const AppRoutes = {
  InboxPage: "/email-client/inbox-page",
  EmailDetails: "/email-client/email-detail-page",
  LoginPage: "/",
  StarredPage: "/email-client/starred-page",
  SnoozedPage: "/email-client/snoozed-page",
  DraftPage: "/email-client/draft-page",
  ImportantPage: "/email-client/important-page",
  ScheduledPage: "/email-client/scheduled-page",
  AllMailPage: "/email-client/all-mail-page",
  SpamPage: "/email-client/spam-page",
  TrashPage: "/email-client/trash-page",
  SentPage: "/email-client/sent-page",
  FolderPage: "/email-client/folder-page",
  EmailPage: "/email-client/email-page",
};

export default AppRoutes;
