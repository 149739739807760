import PageSpinner from "components/PageSpinner";
import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import * as AppLoadable from "./AppLoadable";
import AppRoutes from "./appRoutes";

function Application() {
  return (
    <Routes>
      <Route
        element={
          <Suspense fallback={<PageSpinner size={46} />}>
            <AppLoadable.InboxPage />
          </Suspense>
        }
        path={AppRoutes.InboxPage}
      />
      <Route
        element={
          <Suspense fallback={<PageSpinner size={46} />}>
            <AppLoadable.LoginPage />
          </Suspense>
        }
        path={`${AppRoutes.LoginPage}/:token`}
      />
      <Route
        element={
          <Suspense fallback={<PageSpinner size={46} />}>
            <AppLoadable.StarredPage />
          </Suspense>
        }
        path={AppRoutes.StarredPage}
      />

      <Route
        element={
          <Suspense fallback={<PageSpinner size={46} />}>
            <AppLoadable.DraftPage />
          </Suspense>
        }
        path={AppRoutes.DraftPage}
      />

      <Route
        element={
          <Suspense fallback={<PageSpinner size={46} />}>
            <AppLoadable.ImportantPage />
          </Suspense>
        }
        path={AppRoutes.ImportantPage}
      />

      <Route
        element={
          <Suspense fallback={<PageSpinner size={46} />}>
            <AppLoadable.TrashPage />
          </Suspense>
        }
        path={AppRoutes.TrashPage}
      />
      <Route
        element={
          <Suspense fallback={<PageSpinner size={46} />}>
            <AppLoadable.SpamPage />
          </Suspense>
        }
        path={AppRoutes.SpamPage}
      />

      <Route
        element={
          <Suspense fallback={<PageSpinner size={46} />}>
            <AppLoadable.SentPage />
          </Suspense>
        }
        path={AppRoutes.SentPage}
      />
      <Route
        element={
          <Suspense fallback={<PageSpinner size={46} />}>
            <AppLoadable.AllMailPage />
          </Suspense>
        }
        path={AppRoutes.AllMailPage}
      />
      <Route
        element={
          <Suspense fallback={<PageSpinner size={46} />}>
            <AppLoadable.FolderPage />
          </Suspense>
        }
        path={`${AppRoutes.FolderPage}/:folderName`}
      />
    </Routes>
  );
}

export default Application;
