import ReactDOM from "react-dom/client";
import { SocketProvider } from "SocketContext";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <SocketProvider>
    <App />
  </SocketProvider>,
);
reportWebVitals();
