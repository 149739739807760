import { lazy } from "react";

const InboxPage = lazy(() => import("pages/InboxPage"));
const LoginPage = lazy(() => import("pages/LoginPage"));
const DraftPage = lazy(() => import("pages/DraftPage"));
const ImportantPage = lazy(() => import("pages/ImportantPage"));
const StarredPage = lazy(() => import("pages/StarredPage"));
const SpamPage = lazy(() => import("pages/SpamPage"));

const TrashPage = lazy(() => import("pages/TrashPage"));
const SentPage = lazy(() => import("pages/SentPage"));
const AllMailPage = lazy(() => import("pages/AllMailPage"));

const FolderPage = lazy(() => import("pages/FolderPage"));

export {
  AllMailPage,
  DraftPage,
  FolderPage,
  ImportantPage,
  InboxPage,
  LoginPage,
  SentPage,
  SpamPage,
  StarredPage,
  TrashPage,
};
