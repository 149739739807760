import { Box, CircularProgress } from "@mui/material";
import React from "react";

function PageSpinner(props: any) {
  const { size } = props;
  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      minHeight="100vh"
      minWidth="100vw"
    >
      <CircularProgress color="warning" size={size || 46} />
    </Box>
  );
}

export default PageSpinner;
