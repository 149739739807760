/* eslint-disable import/no-extraneous-dependencies */
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { StyledEngineProvider } from "@mui/system";
import ComposeDialog from "ComposeDialog";
import Application from "navigation/Application";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const theme = createTheme();
function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <BrowserRouter>
              <Application />
            </BrowserRouter>
          </StyledEngineProvider>
        </ThemeProvider>
      </QueryClientProvider>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            width: "320px",
            textAlign: "start",
            padding: "8px",
            marginTop: "8px",
            marginBottom: "8px",
            zIndex: 9999,
          },
          duration: 5000,
        }}
      />
      <ComposeDialog />
    </>
  );
}

export default App;
